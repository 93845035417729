const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const path = {
  loginUrl: `${baseUrl}/qa-login-v2`,
  qaAnalytics: `${baseUrl}/qa-analytics-v2`,
  resolved: `${baseUrl}/resolved-v2`,
  bulkUpload: `${baseUrl}/bulk-upload-v2`,
  getClients: `${baseUrl}/get-clients-v2`,
  categories: `${baseUrl}/categories-v2`,
  edit: `${baseUrl}/qa-edit-card-v2`
};

module.exports = {
  path,
};

