import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Chip } from '@mui/material';
import axios from 'axios';
import { path } from '../../../apiCentral/path';
import Cookies from 'js-cookie';

const SelectUploadTypePopup = ({ open, handleClose, handleSelection }) => {
  const [selectedOption, setSelectedOption] = useState('mainPages');
  const [selectedClients, setSelectedClients] = useState([]);
  const [clients, setClients] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const token = Cookies.get('token');

    axios.post(path.getClients, {}, {
      headers: { Authorization: token }
    })
      .then(response => {
        if (Array.isArray(response.data.clients)) {
          setClients(response.data.clients);
        }
      })
      .catch(error => {
        // console.error("There was an error fetching the clients!", error);
      });
  }, []);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleClientChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedClients(
      typeof value === 'string' ? value.split(',') : value,
    );
    setIsDropdownOpen(false); 
  };

  const handleConfirm = () => {
    handleSelection({
      uploadType: selectedOption,
      clients: selectedClients,
    });
    handleClose();
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select Client</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel id="client-select-label">Select Client</InputLabel>
          <Select
            labelId="client-select-label"
            id="client-multiple-chip"
            multiple
            open={isDropdownOpen} 
            onOpen={handleDropdownOpen}
            onClose={handleDropdownClose} 
            value={selectedClients}
            onChange={handleClientChange}
            input={<OutlinedInput id="select-multiple-chip" label="Select Clients" />}
            renderValue={(selected) => (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </div>
            )}
            style={{ backgroundColor: 'white' }}
          >
            {Array.isArray(clients) && clients.map((client, index) => (
              <MenuItem key={index} value={client}>
                {client}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
        <Button onClick={handleClose} color="primary" style={{ marginRight: '8px' }}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="primary" disabled={selectedClients.length === 0}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectUploadTypePopup;
