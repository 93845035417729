// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body, html {
    height: 100%;
    font-family: Arial, sans-serif;
  }
  
  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    background-color: #f8f9fa;
    border: none;
  }
  
  .login-popup {
    background: #fff;
    padding: 30px 30px 30px 48px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    width: 100%;
    max-width: 450px;
    border: 1px;
  }
  
  h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input {
    width: calc(100% - 20px);
    padding: 8px;
    margin: 5px 0 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  
  .login-button {
    width: calc(100% - 20px);
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-button:hover {
    background-color: #007BFF;
  }
  `, "",{"version":3,"sources":["webpack://./src/login/Login.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,8BAA8B;EAChC;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,gBAAgB;IAChB,4BAA4B;IAC5B,kBAAkB;IAClB,wCAAwC;IACxC,WAAW;IACX,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,wBAAwB;IACxB,YAAY;IACZ,kBAAkB;IAClB,oCAAoC;IACpC,kBAAkB;EACpB;;EAEA;IACE,wBAAwB;IACxB,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n  }\n  \n  body, html {\n    height: 100%;\n    font-family: Arial, sans-serif;\n  }\n  \n  .login-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 90vh;\n    background-color: #f8f9fa;\n    border: none;\n  }\n  \n  .login-popup {\n    background: #fff;\n    padding: 30px 30px 30px 48px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); \n    width: 100%;\n    max-width: 450px;\n    border: 1px;\n  }\n  \n  h2 {\n    margin-bottom: 20px;\n    text-align: center;\n  }\n  \n  .form-group {\n    margin-bottom: 15px;\n  }\n  \n  label {\n    display: block;\n    margin-bottom: 5px;\n    font-weight: bold;\n  }\n  \n  input {\n    width: calc(100% - 20px);\n    padding: 8px;\n    margin: 5px 0 10px;\n    border: 1px solid rgba(0, 0, 0, 0.1);\n    border-radius: 4px;\n  }\n  \n  .login-button {\n    width: calc(100% - 20px);\n    padding: 10px;\n    background-color: #007BFF;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 16px;\n  }\n  \n  .login-button:hover {\n    background-color: #007BFF;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
