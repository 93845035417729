import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Switch, TextField, MenuItem, IconButton, FormControl, InputLabel, Select, OutlinedInput } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import axios from 'axios';
import { path } from '../../../apiCentral/path';
import Cookies from 'js-cookie';

const FilterPopup = ({
    onClose,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setFilter,
    dataProcess,
    dateRange,
    handleSwitchChange,
    handleResetFilters,
    handleFinalSubmit,
}) => {
    const [clients, setClients] = useState([]);
    //
    useEffect(() => {
        const token = Cookies.get('token');

        axios.post(path.getClients, {}, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                // console.log('API Response:', response.data.clients); 
                if (Array.isArray(response.data.clients)) {
                    setClients(response.data.clients);
                } else {
                    // console.error("Unexpected data format:", response.data);
                }
            })
            .catch(error => {
                // console.error("There was an error fetching the clients!", error);
            });
    }, []);

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleDataProcessChange = (event) => {
        setFilter(event.target.value);
    };

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Filter Options
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="client-label">Client</InputLabel>
                    <Select
                        labelId="client-label"
                        value={dataProcess}
                        onChange={handleDataProcessChange}
                        input={<OutlinedInput label="Client" />}
                    >
                        {Array.isArray(clients) && clients.map((client, index) => (
                            <MenuItem key={index} value={client}>
                                {client}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                        labelId="status-label"
                        value={dataProcess}
                        onChange={handleDataProcessChange}
                        input={<OutlinedInput label="Status" />}
                    >
                        <MenuItem value="Pending">Pending</MenuItem>
                        <MenuItem value="Resolved">Resolved</MenuItem>
                    </Select>
                </FormControl>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, mb: 2 }}>
                    <span>Select Date</span>
                    <Switch
                        checked={dateRange}
                        onChange={handleSwitchChange}
                        color="primary"
                    />
                    <span>Select Date Range</span>
                </Box>

                <TextField
                    label="Start Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    value={startDate}
                    onChange={handleStartDateChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{ marginBottom: 2 }}
                />

                {dateRange && (
                    <TextField
                        label="End Date"
                        type="date"
                        variant="outlined"
                        fullWidth
                        value={endDate}
                        onChange={handleEndDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ marginBottom: 2 }}
                    />
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button onClick={handleResetFilters} color="primary">
                    RESET FILTERS
                </Button>
                <Button onClick={handleFinalSubmit} color="primary" variant="contained">
                    APPLY FILTERS
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FilterPopup;
