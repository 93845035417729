import React, { useEffect, useState } from 'react';
import {
  Box, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  List, 
  ListItem, 
  ListItemIcon,
  ListItemText, 
  IconButton, 
  Typography, 
  Tooltip
} from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import Cookies from 'js-cookie';
import { openDB } from 'idb';
import axios from 'axios';
import { path } from '../../../apiCentral/path';

const STATUS = {
  COMPLETED: 'Successfully Uploaded',
  FAILED: 'Failed',
};

const UploadStatusPopup = ({ open, handleClose, uploadedFiles, uploadStatus, handleDelete, uploadType,  }) => {
  const fileProgress = uploadedFiles.map(file => ({
    file,
    status: uploadStatus ? STATUS.COMPLETED : STATUS.FAILED,
  }));

  const [storedFiles, setStoredFiles] = useState([]);

  const initDB = async () => {
    const db = await openDB('fileStore', 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('files')) {
          db.createObjectStore('files', { keyPath: 'id', autoIncrement: true });
        }
      },
    });
    return db;
  };

  // Fetch stored files from IndexedDB when the popup opens
  useEffect(() => {
    const fetchStoredFiles = async () => {
      try {
        const db = await initDB();
        const tx = db.transaction('files', 'readonly');
        const store = tx.objectStore('files');
        const files = await store.getAll();
        // console.log('Fetched files:', files); 
        setStoredFiles(files.map(file => ({
          file,
          status: uploadStatus ? STATUS.COMPLETED : STATUS.FAILED,
        })));
      } catch (error) {
        // console.error('Error retrieving files from IndexedDB:', error);
      }
    };

    if (open) {
      fetchStoredFiles();
    }
  }, [open]);

  useEffect(() => {
    setStoredFiles(fileProgress);
  }, [uploadedFiles, uploadStatus]);

  // Function to clear IndexedDB
  useEffect(() => {
    const clearIndexedDB = async () => {
      try {
        const db = await initDB();
        const tx = db.transaction('files', 'readwrite');
        const store = tx.objectStore('files');
        await store.clear();
        await tx.done;
        Cookies.remove('uploadedFiles', { path: '/' });
      } catch (error) {
        console.error('Error clearing IndexedDB:', error);
      }
    };

    if (uploadStatus) {
      clearIndexedDB();
    }
  }, [uploadStatus]);

  //To delete the files
  const handleDeleteFile = async (file) => {
    try {
      const db = await initDB();
      const tx = db.transaction('files', 'readwrite');
      const store = tx.objectStore('files');
      await store.delete(file.id);
      await tx.done;

      const updatedFiles = storedFiles.filter(storedFile => storedFile.file.id !== file.id);
      // console.log('Updated files after delete:', updatedFiles); 
      setStoredFiles(updatedFiles);
      if (handleDelete) {
        handleDelete(file);
      }
    } catch (error) {
      // console.error('Error deleting file from IndexedDB:', error);
    }
  };

  const prepareJsonData = (files) => ({
    files: files
      .filter(file => file.base64)
      .map(file => ({
        name: file.name,
        base64: file.base64,
        size: file.size,
      })),
  });

  const determineImageCount = (uploadType) => {
    let imageCount = 2;

    if (uploadType === 'mainPages') {
      imageCount = 1;
    } else if (uploadType === 'frontBack') {
      imageCount = 2;
    }

    return imageCount;
  };

  const handleRetryAll = async () => {
    try {
      const failedFiles = storedFiles.filter(file => file.status === STATUS.FAILED);
      if (failedFiles.length === 0) return;
  
      const fileType = failedFiles.some(file => file.file.type === 'application/zip') ? 'zip' : 'image';
      const imageCount = determineImageCount(uploadType);
      const jsonData = prepareJsonData(failedFiles);
      const dataToSend = {
        ...jsonData,
        file_type: fileType,
        image_count: imageCount,
      };
      const token = Cookies.get('token');
      if (!token) {
        throw new Error('No token found');
      }
  
      setStoredFiles(prevFiles =>
        prevFiles.map(file =>
          file.status === STATUS.FAILED ? { ...file, loading: true } : file
        )
      );
  
      const response = await axios.post(path.bulkUpload, dataToSend, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
      });
  
      const db = await initDB();
      const tx = db.transaction('files', 'readwrite');
      const store = tx.objectStore('files');
  
      for (const file of failedFiles) {
        await store.delete(file.file.id);
      }
      await tx.done;
      setStoredFiles(prevFiles =>
        prevFiles.map(file =>
          file.status === STATUS.FAILED
            ? { ...file, status: STATUS.COMPLETED, loading: false }
            : file
        )
      );
  
    } catch (error) {
      // console.error('Error retrying upload:', error);
      setStoredFiles(prevFiles =>
        prevFiles.map(file =>
          file.status === STATUS.FAILED ? { ...file, loading: false } : file
        )
      );
    }
  };
  
  

  const hasFailedFiles = storedFiles.some(fileObj => fileObj.status === STATUS.FAILED);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          width: '40vw',
          height: '55vh',
          maxWidth: 'none',
          maxHeight: 'none',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', position: 'relative' }}>
        <Typography variant="h6">Upload Status</Typography>
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        {hasFailedFiles && (
          <Tooltip title="Retry All Failed Uploads">
            <IconButton
              onClick={handleRetryAll}
              sx={{ position: 'absolute', right: 48, top: 8, color: 'red' }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )}
      </DialogTitle>
      <DialogContent>
        <List>
          {storedFiles.map((fileObj, index) => (
            <ListItem key={index}>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  <ListItemIcon>
                    {fileObj.file.name.endsWith('.zip') || fileObj.file.type === 'application/zip' ? (
                      <InsertDriveFileIcon sx={{ fontSize: { xs: 30, sm: 40, md: 50 }, color: '#808080' }} />
                    ) : (
                      fileObj.file.base64 ? (
                        <img
                          src={fileObj.file.base64}
                          alt={fileObj.file.originalName}
                          style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '4px', marginRight: '10px' }}
                        />
                      ) : (
                        <InsertDriveFileIcon sx={{ fontSize: { xs: 30, sm: 40, md: 50 }, color: '#808080' }} />
                      )
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={fileObj.file.originalName}
                    secondary={fileObj.status}
                    sx={{
                      '& .MuiListItemText-secondary': {
                        color: fileObj.status === STATUS.COMPLETED ? 'green' : 'red',
                      },
                      '& .MuiListItemText-primary': {
                        fontSize: { xs: '0.8rem', sm: '1rem', md: '1.2rem' },
                      },
                    }}
                  />
                  {fileObj.status === STATUS.FAILED && (
                    <Tooltip title="Delete">
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteFile(fileObj.file)}
                        sx={{
                          color: 'inherit',
                          '&:hover': {
                            color: 'red',
                          },
                          fontSize: { xs: 'small', sm: 'medium' },
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '16px', width: '100%' }}>
          <Button onClick={handleClose} color="primary" sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>
            Cancel
          </Button>
          <Button onClick={handleClose} variant="contained" color="primary" sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>
            Continue
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default UploadStatusPopup;
