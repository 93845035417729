import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Typography,
  Tooltip,
  Divider,
  ListItemIcon,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import UploadStatusPopup from './UploadStatusPopup';
import AddNewCardsPopup from './AddNewCardsPopup';
import Cookies from 'js-cookie';
import { openDB } from 'idb';
import { path } from '../../../apiCentral/path';

const SelectedFilesPopup = ({ open, handleDelete, uploadType, uploadedFiles, handleClose }) => {
  const [storedFiles, setStoredFiles] = useState([]);
  const [uploadStatusOpen, setUploadStatusOpen] = useState(false);
  const [addNewCardsOpen, setAddNewCardsOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [failedFiles, setFailedFiles] = useState([]);
  const [popupWidth, setPopupWidth] = useState('600px');
  const [popupHeight, setPopupHeight] = useState('auto');
  const [selectedImage, setSelectedImage] = useState(null);

  //Fetch the files from indexed db
  useEffect(() => {
    const fetchStoredFiles = async () => {
      try {
        const db = await openDB('fileStore', 1, {
          upgrade(db) {
            if (!db.objectStoreNames.contains('files')) {
              db.createObjectStore('files', { keyPath: 'id', autoIncrement: true });
            }
          },
        });

        const tx = db.transaction('files', 'readonly');
        const store = tx.objectStore('files');
        const files = await store.getAll();
        if (Array.isArray(files)) {
          setStoredFiles(files);
        }
      } catch (error) {
        console.error('Error retrieving files from IndexedDB:', error);
      }
    };

    if (open) {
      fetchStoredFiles();
    }
  }, [open]);

  // Prepares the JSON data to be sent to the backend by filtering out the files with base64 and formatting them properly
  const prepareJsonData = (files) => ({
    files: files
      .filter(file => file.base64)
      .map(file => ({
        originalName: file.originalName,
        name: file.name,
        base64: file.base64,
        size: file.size,
      })),
  });

   // Determines the image count based on the upload type (mainPages or frontBack)
  const determineImageCount = (uploadType) => {
    let imageCount = 2;

    if (uploadType === 'mainPages') {
      imageCount = 1;
    } else if (uploadType === 'frontBack') {
      imageCount = 2;
    }

    return imageCount;
  };

  // Handles the "Next" button click, prepares the files and data to send to the server and handles the API request
  const handleNext = async () => {
    try {
      const fileType = storedFiles.some(file => file.type === 'application/zip') ? 'zip' : 'image';
      const imageCount = determineImageCount(uploadType);
      const jsonData = prepareJsonData(storedFiles);
      const dataToSend = {
        ...jsonData,
        file_type: fileType,
        image_count: imageCount,
      };

      const token = Cookies.get('token');
      if (!token) {
        throw new Error('No token found');
      }
      setStoredFiles(prevFiles => prevFiles.map(file => ({ ...file, loading: true })));
      const response = await axios.post(path.bulkUpload, dataToSend, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
      });
      setUploadStatus(response.data.status);
      setUploadStatusOpen(true);
    } catch (error) {
      console.error('Error uploading files:', error);
      setUploadStatus(false);
      setUploadStatusOpen(true);
    }
    finally {
      setStoredFiles(prevFiles => prevFiles.map(file => ({ ...file, loading: false })));
    }
  };

  const handleBack = () => {
    handleClose();
  };

  const closeAddNewCards = () => {
    setAddNewCardsOpen(false);
  };

  // Formats the file size into a human-readable format (e.g., KB, MB, GB)
  const formatFileSize = (size) => {
    if (size === undefined) return 'Unknown size';
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (size === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(size) / Math.log(1024)));
    return Math.round(size / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  // Retries the file upload process if it failed the first time
  const retryUpload = (file) => {
    const uploadFile = async (file) => {
      try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch(path.bulkUpload, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Upload failed');
        }
        setFailedFiles(prevFiles => prevFiles.filter(f => f !== file));
      } catch (error) {
        console.error('Upload failed', error);
      }
    };

    uploadFile(file);
  };

  // Handles the file deletion process, removes the file from IndexedDB and updates the stored files list
  const handleDeleteFile = async (file) => {
    const db = await openDB('fileStore', 1);
    const tx = db.transaction('files', 'readwrite');
    const store = tx.objectStore('files');
    await store.delete(file.id);
    await tx.done;

    const updatedFiles = storedFiles.filter(storedFile => storedFile.id !== file.id);
    setStoredFiles(updatedFiles);
    if (handleDelete) {
      handleDelete(file);
    }
  };

  // Handles thumbnail click to display the selected image in a larger view
  const handleThumbnailClick = (file) => {
    setSelectedImage(file.base64);
  };

  const closeImagePopup = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    if (uploadStatusOpen) {
      setPopupWidth('0px');
      setPopupHeight('0px');
    } else {
      setPopupWidth('600px');
      setPopupHeight('auto');
    }
  }, [uploadStatusOpen]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="xl"
        fullWidth={false}
        PaperProps={{
          style: {
            width: popupWidth,
            height: popupHeight,
          },
        }}>
        <DialogTitle sx={{ position: 'relative', textAlign: 'center' }}>
          <Typography variant="h6">Selected Files</Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'grey.500',
              '&:hover': {
                color: 'black',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {storedFiles.length > 0 ? (
            <List sx={{ backgroundColor: '#f5f5f5', padding: '15px', borderRadius: '8px', maxHeight: '400px', overflowY: 'auto' }}>
              {storedFiles.map((file) => (
                <React.Fragment key={file.id}>
                  <ListItem
                    sx={{
                      border: '1px solid #e0e0e0',
                      borderRadius: '8px',
                      marginBottom: '8px',
                      padding: '10px',
                      backgroundColor: '#ffffff',
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <ListItemIcon>
                        {file.loading ? (
                          <CircularProgress size={50} sx={{ color: '#1976d2' }} />
                        ) : file.type === 'application/zip' ? (
                          <InsertDriveFileIcon sx={{ fontSize: 50, color: '#808080' }} />
                        ) : (
                          <img
                            src={file.base64}
                            alt={file.originalName}
                            style={{ width: '35px', height: '35px', objectFit: 'cover', borderRadius: '4px', cursor: 'pointer' }}
                            onClick={() => handleThumbnailClick(file)}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={file.originalName}
                        secondary={formatFileSize(file.size)}
                        sx={{ marginLeft: 2 }}
                      />
                      <Tooltip title="Delete">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteFile(file)}
                          sx={{
                            '&:hover': {
                              color: 'red',
                            },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </ListItem>
                  {storedFiles.indexOf(file) !== storedFiles.length - 1 && (
                    <Divider variant="middle" sx={{ marginY: '8px' }} />
                  )}
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Typography>No files selected.</Typography>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleBack} color="primary">
            Back
          </Button>
          <Button
            onClick={handleNext}
            color="primary"
            disabled={storedFiles.length === 0}
            style={{ backgroundColor: '#1976d2', color: '#fff' }}
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
      {uploadStatusOpen && <UploadStatusPopup open={uploadStatusOpen}
        handleClose={handleClose}
        uploadedFiles={storedFiles}
        handleDelete={handleDelete}
        uploadStatus={uploadStatus}
        retryUpload={retryUpload}
        uploadType={uploadType}
      />}
      <Dialog open={!!selectedImage} onClose={closeImagePopup} maxWidth="md">
        <DialogContent>
          {selectedImage && <img src={selectedImage} alt="Full view" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeImagePopup} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectedFilesPopup;
