const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const path = {
  loginUrl: `${baseUrl}/qa-login`,
  qaAnalytics: `${baseUrl}/qa-analytics`,
  resolved: `${baseUrl}/resolved`,
  bulkUpload: `${baseUrl}/bulk-upload`,
  getClients: `${baseUrl}/get-clients`,
  categories: `${baseUrl}/categories`,
  edit: `${baseUrl}/qa-edit-card`
};

module.exports = {
  path,
};

